import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import PregnantMultiselect from '../components/Elements/PregnantMultiselect';
import axios from 'axios';


export default class PortalForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      heart_diseases: [
        {
            "id": 1,
            "name": "Ишемическая"
        },
        {
            "id": 2,
            "name": "Складки"
        }
      ],
      pregnancy_complications: [
        {
            "id": 1,
            "name": "Ишемическая"
        },
        {
            "id": 2,
            "name": "Складки"
        }
      ],
      allergological_anamnesis: [
        {
            "id": 1,
            "name": "Ишемическая"
        },
        {
            "id": 2,
            "name": "Складки"
        }
      ],
      bone_fractures: [
        {
            "id": 1,
            "name": "Ишемическая"
        },
        {
            "id": 2,
            "name": "Складки"
        }
      ],
      heart_diseases_selected: [],
      pregnancy_complications_selected: [],
      allergological_anamnesis_selected: [],
      bone_fractures_selected: [],
      pregnant_client: {
        name: '',
        surname: '',
        patronymic: 'asdsadsa',
        phone: '',
        address: '',
      },      
    }; 
  }
  heart_diseases_loaded = false
  pregnancy_complications = []
  allergological_anamnesis = []
  bone_fractures = []
  getHeartDiseasesData = () => {
      axios.get('http://10.10.10.40:8080/api/heart_diseases')
      .then(({data}) => {
        this.setState({heart_diseases: data})
      })
  }
  getPregnancyComplicationsData = () => {
      axios.get('http://10.10.10.40:8080/api/complications_of_pregnancy')
      .then(({data}) => {
        this.setState({pregnancy_complications: data})
      })
  }
  getAllergologicalAnamnesisData = () => {
      axios.get('http://10.10.10.40:8080/api/allegorical_anamnesis')
      .then(({data}) => {
        this.setState({allergological_anamnesis: data})
      })
  }
  getBoneFracturesData = () => {
      axios.get('http://10.10.10.40:8080/api/bone_fractures')
      .then(({data}) => {
        this.setState({bone_fractures: data})
      })
    }
  post_initial_state = () => {
    let allergological_anamnesis_selected_post = []
    for (let i=0; i<this.state.allergological_anamnesis_selected.length; i++){
      allergological_anamnesis_selected_post.push({name: this.state.allergological_anamnesis_selected[i].id})
    }
    let data = {
      heart_disease: this.state.heart_diseases_selected[0],
      complications_of_pregnancy : {name: this.state.pregnancy_complications_selected[0].id},
      bone_fractures: {name: this.state.bone_fractures_selected[0].id},
      allegorical_anamnesis: allergological_anamnesis_selected_post,
      pregnant_client: this.state.pregnant_client
    }
    console.log(this.data)
    axios({
      method: "post",
      url: "http://10.10.10.40:8080/api/initial_state",
      data: data,
      headers: {'Content-Type': 'application/json'},
    })
    .then((response) => {
      console.log(response)
    })
  }
  handleTextInput = (event) => {
    const updatedPregnantClient = {
      ...this.state.pregnant_client,
      [event.target.name]: event.target.value
    }
    this.setState({
      pregnant_client: updatedPregnantClient
    })
  }
  heartDiseaseshandleSelect = (selectedList) => {
    this.setState({ heart_diseases_selected: selectedList });
  }
  heartDiseaseshandleRemove = (selectedList, removedItem) => {
    this.setState({ heart_diseases_selected: selectedList });
  }
  pregnancyComplicationshandleSelect = (selectedList) => {
    this.setState({ pregnancy_complications_selected: selectedList });
  }
  pregnancyComplicationshandleRemove = (selectedList, removedItem) => {
    this.setState({ pregnancy_complications_selected: selectedList });
  }
  allergologicalAnamnesishandleSelect = (selectedList) => {
    this.setState({ allergological_anamnesis_selected: selectedList });
  }
  allergologicalAnamnesishandleRemove = (selectedList, removedItem) => {
    this.setState({ allergological_anamnesis_selected: selectedList });
  }
  boneFractureshandleSelect = (selectedList) => {
    this.setState({ bone_fractures_selected: selectedList });
  }
  boneFractureshandleRemove = (selectedList, removedItem) => {
    this.setState({ bone_fractures_selected: selectedList });
  }
  componentDidMount() {
    // this.getHeartDiseasesData()
    // this.getAllergologicalAnamnesisData()
    // this.getBoneFracturesData()
    // this.getPregnancyComplicationsData()
  }
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Card mt-4>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Фамилия
                    </Form.Label>
                    <Form.Control name="surname" onChange={this.handleTextInput} value={this.state.pregnant_client.surname} type="text">
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Имя
                    </Form.Label>
                    <Form.Control name="name" onChange={this.handleTextInput} value={this.state.pregnant_client.name} type="text">
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Телефон
                    </Form.Label>
                    <Form.Control name="phone" onChange={this.handleTextInput} value={this.state.pregnant_client.phone} type="text">
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Адрес
                    </Form.Label>
                    <Form.Control name="address" onChange={this.handleTextInput} value={this.state.pregnant_client.address} type="text">
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          </Col>
          <Col>
          <Card mt-4>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Лечащий врач
                  </Form.Label>
                  <Form.Control type="text">
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Поликлиника
                  </Form.Label>
                  <Form.Control type="text">
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    Телефон поликлиники
                  </Form.Label>
                  <Form.Control type="text">
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Card>
            <Card.Body>
                <Form.Group>
                  <Form.Label>
                    Болезни сердца
                  </Form.Label>
                <PregnantMultiselect 
                options={this.state.heart_diseases} 
                onSelect={this.heartDiseaseshandleSelect}
                onRemove={this.heartDiseaseshandleRemove}
                selectedValues={this.state.heart_diseases_selected}>
                </PregnantMultiselect>
                </Form.Group>
                </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Form.Group>
                    <Form.Label>
                      Осложнения беременности
                    </Form.Label>
                    <PregnantMultiselect
                    onSelect={this.pregnancyComplicationshandleSelect}
                    onRemove={this.pregnancyComplicationshandleRemove}
                    options={this.state.pregnancy_complications} 
                    selectedValues={this.state.pregnancy_complications_selected}>
                    </PregnantMultiselect>
                  </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>
                          Аллергологический анамнез
                        </Form.Label>
                        <PregnantMultiselect
                          onSelect={this.allergologicalAnamnesishandleSelect}
                          onRemove={this.allergologicalAnamnesishandleRemove}
                          options={this.state.allergological_anamnesis} 
                          selectedValues={this.state.allergological_anamnesis_selected}>
                          </PregnantMultiselect>
                        </Form.Group>
                      </Card.Body>
                    </Card>    
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <Form.Group>
                          <Form.Label>
                            Были ли переломы костей
                          </Form.Label>
                          <PregnantMultiselect
                          onSelect={this.boneFractureshandleSelect}
                          onRemove={this.boneFractureshandleRemove}
                          options={this.state.bone_fractures}
                          selectedValues={this.state.bone_fractures_selected}>
                          </PregnantMultiselect>
                        </Form.Group>
                      </Card.Body>
                    </Card>
                    </Col>
                  </Row>
        <Button className="btn btn-danger w-100 mt-5" style={{fontSize: "50px"}} onClick={this.post_initial_state}>Запустить ракету</Button>
      </Container>
    )
  }
}