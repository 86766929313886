import React, { Component }  from 'react';
import PregnantTable from '../components/Elements/PregnantTable';


export default class PortalForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
        pregnant_clients_headers: [],
        pregnant_clients_data: {},
        itemsPerPage : 10,
        currentPage: 1,
    }
  }
  componentDidMount() {
  }
  render() {
    return (
      <div style={{width: '95%', padding: '0',}}>
        <h1>Table with Pagination</h1>
        <PregnantTable itemsPerPage={this.state.itemsPerPage}/>
      </div>
    )
  }
}