import React, { Component } from 'react';
import Multiselect from 'multiselect-react-dropdown';

class PregnantMultiselect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedValues: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.options !== this.props.options
    ){
      this.setState({options: this.props.options});
      this.forceUpdate();
    }
    if (prevProps.selectedValues !== this.props.selectedValues) {
      this.setState({ selectedValues: this.props.selectedValues });
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Multiselect
        options={this.state.options}
        onSelect={this.props.onSelect}
        selectedValues={this.state.selectedValues}
        displayValue="name"
        avoidHighlightFirstOption={ true }
      />
    );
  }
}

export default PregnantMultiselect;