import React, { Component } from 'react';
import { Table, Pagination, } from 'react-bootstrap';
import { PregnantMainPopup } from '../PregnantMainPopup';
// import axios from 'axios';

class PregnantTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      data: {},
      dataCount: 0,
      columns: [],
      currentDataLoaded: false,
      test_data: {
        "count": 1,
        "next": "foo",
        "previous": "bar",
        "results": [
          {		
            id:	1	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	2	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	3	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	4	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	5	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	6	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	7	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	8	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	9	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	10	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	11	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	12	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	13	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	14	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	15	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	16	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	17	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	18	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	19	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	20	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	21	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	22	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	23	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	24	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	25	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	26	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	27	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	28	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	29	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	30	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	31	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	32	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	33	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	34	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	35	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	36	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	37	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	38	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	39	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	40	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	41	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	42	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	43	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	44	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	45	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
            {		
            id:	46	,
            date:	"11.02.2020"	,
            area:	"TEST REGION"	,
            name_of_medical_organization:	"test_medical_organization"	,
            patient_full_name:	"TEST PATIENT NAME"	,
            year_of_birth:	"1993"	,
            age:	"age"	,
            phone:	"87082254301"	,
            },		
        ]
      },
    };
  }
  
  getPregnantClients = async (page) => {
    try{
      // let request = await axios.get(`http://10.10.10.40:8080/api/pregnant_client?p=${page}`);
      // if (request.status === 200) {
        let data = this.state.test_data;
        let columns = Object.keys(data.results[0]);
        const updatedPregnantClient = {
          ...this.state.data,
          [page]: data.results,
        };
        this.setState({
          dataCount: parseInt(data.count),
          data: updatedPregnantClient,
          columns: columns,
          currentPage: page,
        },
        () => {
          this.setState({ currentDataLoaded: true })        
        })
      }
    // }
    catch(err){
      console.log(err)
    }
  }
  triggerPregnantMainPopup() {
    
  }
  componentDidMount() {
    this.setState({currentPage: 1})
  }

componentDidUpdate(prevState) {
  if (prevState.currentPage !== this.state.currentPage && !this.state.currentDataLoaded) {
    this.getPregnantClients(this.state.currentPage);
  }
}


render() {
  const itemsPerPage = this.props.itemsPerPage;

    return (
      <div style={{ padding: '0', width: '95%', margin: '0 auto' }}>
        {this.state.currentDataLoaded && (
          <Table style={{ fontSize: '24px' }} bordered>
            {console.log(this.state.data[this.state.currentPage])}
            <thead>
              <tr>
                {this.state.columns.map((column) => (
                  <th key={column}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.data[this.state.currentPage].map((item, index) => (
                <tr key={index} onClick={this.triggerPregnantMainPopup}>
                  {Object.values(item).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {this.state.currentDataLoaded && (
          <Pagination>
            <Pagination.First onClick={() => this.setState({currentPage: 1, currentDataLoaded: false} )} disabled={this.state.currentPage === 1} />
            <Pagination.Prev
              onClick={() => this.setState({currentPage: this.state.currentPage - 1, currentDataLoaded: false})}
              disabled={this.state.currentPage === 1}
            />
            {[...Array(Math.ceil(this.state.dataCount / itemsPerPage))].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === this.state.currentPage}
                onClick={() => this.setState({currentPage: index + 1, currentDataLoaded: false })}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => this.setState({currentPage: this.state.currentPage + 1, currentDataLoaded: false})}
              disabled={this.state.currentPage === Math.ceil(this.state.dataCount / itemsPerPage)}
            />
            <Pagination.Last
              onClick={() => this.setState({currentPage: Math.ceil(this.state.dataCount / itemsPerPage), currentDataLoaded: false})}
              disabled={this.state.currentPage === Math.ceil(this.state.dataCount / itemsPerPage)}
            />
          </Pagination>
        )}
      </div>
    );
  }
}

export default PregnantTable;