import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { FormControl, FormText } from 'react-bootstrap';
import PregnantMultiselect from './Elements/PregnantMultiselect';
import PregnantTable from './PregnantTable copy';


export default class PregnantMainPopup extends Component{
  constructor(props) {
    super(props);
    this.state = {
        
    }
  }
  render() {
    return  null 
  }
  componentDidMount() {
    
  }
  
//   componentDidUpdate() {
//     render(
//         <Container>
//           <Row>
//               <Col>
//                   <Card>
//                       <Card.Header>
//                           Личные данные
//                       </Card.Header>
//                       <Card.Body>
//                           <Row>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>ФИО</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Номер телефона</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Возраст</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Текущая неделя беременности</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                           </Row>
//                           <Row>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>ПМСП</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Дата взятия на учёт</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Всего беременностей</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                           </Row>
//                           <Row>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Текущая беременность</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Предполагаемая дата родов</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                           </Row>
//                           <Row>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Дата снятия с учёта</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Причина снятия</Form.Label>
//                                       <FormText></FormText>
//                                       <FormControl type="text"></FormControl>
//                                   </Form.Group>
//                               </Col>
//                           </Row>
//                       </Card.Body>
//                   </Card>
//               </Col>
//               <Col>
//                   <Card>
//                       <Card.Header>
//                           Осложнения беременности
//                       </Card.Header>
//                       <Card.Body>
//                           <Row>
//                               <Col>
//                                   <Form.Group>
//                                       <Form.Label>Факторы риска</Form.Label>
//                                       <FormText></FormText>
//                                       <PregnantMultiselect></PregnantMultiselect>
//                                   </Form.Group>
//                               </Col>
//                               <Col>
//                               <Form.Group>
//                                       <Form.Label>Сопутствующие заболевания</Form.Label>
//                                       <FormText></FormText>
//                                       <PregnantMultiselect></PregnantMultiselect>
//                                   </Form.Group>
//                               </Col>
//                           </Row>
//                           <PregnantTable></PregnantTable>
//                       </Card.Body>
//                   </Card>
//               </Col>
//               <Col>
//                   <Card>
//                       <Card.Header>
//                           Чек-лист состояния здоровья
//                       </Card.Header>
//                       <Card.Body>
//                           <PregnantTable></PregnantTable>
//                       </Card.Body>
//                   </Card>
//               </Col>
//           </Row>
//           <Row className="mt-5">
//               <Col>
//                   <Card>
//                       <Card.Header>
//                           История звонков      
//                       </Card.Header>
//                       <Card.Body>
//                           <PregnantTable></PregnantTable>
//                       </Card.Body>
//                   </Card>
//               </Col>
//               <Col>
//                   <Card>
//                       <Card.Header>
//                           Выявленные нарушения
//                       </Card.Header>
//                       <Card.Body>
//                           <PregnantTable></PregnantTable>
//                       </Card.Body>
//                   </Card>
//               </Col>
//               <Col>
//                   <Card>
//                       <Card.Header>
//                           Чек-лист качества обслуживания   
//                       </Card.Header>
//                       <Card.Body>
//                           <PregnantTable></PregnantTable>
//                       </Card.Body>
//                   </Card>
//               </Col>
//           </Row>
//         </Container>
//       )
//   }
}
