import './App.css';
import Router from './Router'
import PregnantHeader from './components/PortalHeader/PortalHeader';


function App() {
  return (
    <div className="App">
        <PregnantHeader></PregnantHeader>
        <Router></Router>
    </div>
  );
}

export default App;
