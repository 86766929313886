import React from 'react';
import { 
    Routes, 
    Route,
} from 'react-router-dom';
import PortalForm from './pages/PortalForm';
import PortalTable from './pages/PortalTable';
import PregnantMainPopup from './components/PregnantMainPopup';


const Router = () => {
  return (
      <Routes>
        <Route path="/PortalForm" element={ <PortalForm /> }></Route>
        <Route path="/PortalTable" element={ <PortalTable /> }></Route>
        <Route path="/PregnantMainPopup" element={ <PregnantMainPopup /> }></Route>
      </Routes>
  );
}

export default Router;