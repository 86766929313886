import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";


export default function PregnantHeader() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };


  return (
    <header className="Header">
      <CSSTransition
        in={!isSmallScreen}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit>
        <nav className="Nav">
          <Link style={{ color: location.pathname === '/' ? "red" : "blue" }} to="/">Home</Link>
          <Link style={{ color: location.pathname === '/PortalForm' ? "red" : "blue" }} to="/PortalForm">PortalForm</Link>
          <Link style={{ color: location.pathname === "/PortalTable" ? "red" : "blue" }} to="/PortalTable">PortalTable</Link>
          <Link style={{ color: location.pathname === "/PregnantMainPopup" ? "red" : "blue" }} to="/PregnantMainPopup">PregnantMainPopup</Link>
          <Button className="btn-danger">Logout</Button>
        </nav>
      </CSSTransition>

    </header>
  );
}
